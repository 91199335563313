.expandedImagesArrow {
    transition: 0.1s;
    cursor: pointer;

    pointer-events: none;
    position: absolute;
}

.animate {
    transform: translateX(-20px);
}

/* @media (max-width: 820px) {
    .animate {
        transform: translateX(0px);
    }
} */

/* .expandedImagesArrow:active {
    animation: shake 0.5s;

    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(12px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-12px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-32px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
} */

.img {
    transition: 1s;
    /* background-color: blue; */
    object-fit: contain;

}