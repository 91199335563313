.heading {
    position: absolute;
    transform: translate(-35%, 20%);
    transition: 1s;
    z-index: 100;
}

.headingText {
    transition: font-size 0.6s, left 0.5s, right 0.5s, opacity 1s, top 0.5s;
    font-weight: bold;
    position: absolute;
    line-height: 1;
}

/* .headingText:hover {
    color: rgb(115, 192, 255);
} */

.headingContainer {
    position: absolute;
    background-color: rgb(255, 255, 255);
    z-index: -50;
    top: 0;
    transition: 2s;
    border: 2px solid black;
    border-radius: 2vw;
}

.headingOverlay {
    /* background-color: rgba(0, 0, 0, 0.477); */
    position: absolute;
    height: 160vh;
    width: 125vw;
    transform: translate(-45%, -25%);
    transition: 1s;
    z-index: -51;
}

.headingClose {
    left: 18vw;
    top: 1vh;
    position: absolute;
    font-family: LXGmodded;
    cursor: pointer;
}

@media (max-width: 820px) {
    .headingClose {
        left: 32vw;
        top: 0;
    }
}

.headingClose:hover {
    color: red;
}

.para {
    position: absolute;
    left: -30vh;
    height: 20vh;
    width: 30vw;
    font-family: 'LXGmodded';
    text-align: center;
    transition: opacity 1s;

    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
}

@media (max-width: 820px) {
    .para {
        position: absolute;
        left: -30vh;
        height: 20vh;
        width: 70vw;
        font-family: 'LXGmodded';
        text-align: center;
        transition: opacity 1s;

        user-select: text;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
    }
}