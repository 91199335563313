.alphabet {
    display: flex;
    /* height: 16vh;
    width: 10vw; */
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: black;
    transition: 0.1s, width 0.4s, height 0.4s;
    /* background-color: red; */

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

}



.info {
    /* position: absolute; */
    /* top: 17vh; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    height: 0.5vh;
    width: 1.5vw;
    text-align: center;
    font-size: 1vw;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

@media (max-width: 820px) {
    .info {
        height: 3.5vh;
        width: 4vw;
        font-size: 2vw;
    }

}

.infoButton {
    border-radius: 100px;
    border: 1px solid black;

}

.alphabetContainer {
    background-color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: LXGmodded;
    border-radius: 10px;
    color: rgba(24, 24, 24, 0.829);
    box-shadow: 2px 2px 5px 1px black;
    transition: 0.5s;
}

.alphabetContainer-hover {
    background-color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: LXGmodded;
    border-radius: 10px;
    color: rgba(24, 24, 24, 0.829);
    box-shadow: 2px 2px 5px 5px black;
    transition: 0.2s;
}

.alphabetContainer-audio {
    background-color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: LXGmodded;
    border-radius: 10px;
    color: rgba(24, 24, 24, 0.829);
    box-shadow: 2px 2px 5px 5px black;
    transition: 0.2s;
}

.closeButton {
    cursor: pointer;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.closeButton:hover {
    color: red;
}

.rainbow-bg {
    animation: rainbow-bg 2.5s linear;
    animation-iteration-count: infinite;
}

@keyframes rainbow-bg {
    100%, 0% {
        box-shadow: 2px 2px 5px 10px rgb(255, 0, 0);
    }

    8% {
        box-shadow: 2px 2px 5px 10px rgb(255, 127, 0);
    }

    16% {
        box-shadow: 2px 2px 5px 10px rgb(255, 255, 0);
    }

    25% {
        box-shadow: 2px 2px 5px 10px rgb(127, 255, 0);
    }

    33% {
        box-shadow: 2px 2px 5px 10px rgb(0, 255, 0);
    }

    41% {
        box-shadow: 2px 2px 5px 10px rgb(0, 255, 127);
    }

    50% {
        box-shadow: 2px 2px 5px 10px rgb(0, 255, 255);
    }

    58% {
        box-shadow: 2px 2px 5px 10px rgb(0, 127, 255);
    }

    66% {
        box-shadow: 2px 2px 5px 10px rgb(0, 0, 255);
    }

    75% {
        box-shadow: 2px 2px 5px 10px rgb(127, 0, 255);
    }

    83% {
        box-shadow: 2px 2px 5px 10px rgb(255, 0, 255);
    }

    91% {
        box-shadow: 2px 2px 5px 10px rgb(255, 0, 127);
    }
}