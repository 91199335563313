body {
    overflow: hidden;
}

.alpha {
    /* text-align: center; */
    overflow: hidden;
    background-color: pink;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
}



.alphabetsLayout {
    display: flex;
    flex: 1;
    background-color: rgb(255, 255, 255);
    justify-content: center;

}

.alphabetsContainer {
    display: flex;
    width: 80%;
    height: 85%;
    /* margin: 20px; */
    /* background-color: rgb(202, 53, 53); */
    border-radius: 30px;
    /* padding: 20px; */
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

@media (max-width: 820px) {
    .alphabetsContainer {
        top: 55%;
        left: 50%;
    }

}


.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.5vw;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

@media (max-width: 820px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 4vw;
        position: absolute;
        bottom: 3vh;
        left: 6vw;

    }

}


.box {
    background-color: rgb(255, 255, 255);
    border: 2px solid #000;
    width: 11vh;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px black;
    cursor: pointer;
    transition: 0.1s;
}

@media (max-width: 820px) {
    .box {
        background-color: rgb(255, 255, 255);
        border: 1px solid #000;
        width: 9vh;
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4vw;
        box-shadow: 0px 0px 5px 0px black;
        cursor: pointer;
        transition: 0.1s;
    }
}

.box:hover {
    background-color: rgb(0, 0, 0);
    /* background-color: rgb(214, 214, 214); */
}

.boxSpan {
    transition: 0.2s;
}