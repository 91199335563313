/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
} */

/* @font-face {
  font-family: 'LXG';
  src: local('LXG'), url(./LXG.ttf) format('truetype');

} */
@font-face {
  font-family: 'LXGmodded';
  src: local('LXGmodded'), url(./LXGmodded.woff) format('woff');
  font-display: block;
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}